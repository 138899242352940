import React, { useState } from "react";
import { Box, TextField, Button, Alert, CircularProgress, Link } from "@mui/material";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";

const FaucetForm = () => {
  const [address, setAddress] = useState("");
  const [loading, setLoading] = useState(false);
  const [txHash, setTxHash] = useState(null);
  const [error, setError] = useState(null);
  const [captchaToken, setCaptchaToken] = useState(null);

  const handleCaptchaChange = (token) => {
    setCaptchaToken(token);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setTxHash(null);
    setError(null);

    if (!captchaToken) {
      setError("Please complete the CAPTCHA.");
      setLoading(false);
      return;
    }

    try {
      const { data: response } = await axios.post("http://localhost:3000/api/faucet/dispense", {
        recipient: address,
        "g-recaptcha-response": captchaToken,
      });
      setTxHash(response.data.hash);
    } catch (err) {
      if(typeof err.response?.data?.error === "string") {
        setError(err.response?.data?.error || "Failed to dispense tokens. Please try again.");
      } else {
        setError("Failed to dispense tokens. Please try again.");
      }
    } finally {
      setLoading(false);
      setCaptchaToken(null);
    }
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{
        mt: 4,
        width: "100%",
        maxWidth: 450,
        backgroundColor: "background.paper",
        p: 3,
        borderRadius: 2,
        boxShadow: 4,
      }}
    >
      {txHash && (
        <Alert severity="success">
          Tokens dispensed!{" "}
          <Link
            href={`https://explorer.mainnet.bitfinity.network/tx/${txHash}`}
            target="_blank"
            rel="noopener noreferrer"
            underline="always"
            color="inherit"
          >
            View transaction
          </Link>
        </Alert>
      )}
      {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}

      <TextField
        label="Wallet Address"
        variant="outlined"
        fullWidth
        margin="normal"
        value={address}
        onChange={(e) => setAddress(e.target.value)}
        InputLabelProps={{ style: { color: "#b39ddb" } }}
      />

      <ReCAPTCHA
        sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
        onChange={handleCaptchaChange}
        style={{ marginTop: 16 }}
      />

      <Button
        type="submit"
        variant="contained"
        color="primary"
        fullWidth
        disabled={loading || !address || !captchaToken}
        sx={{ mt: 2 }}
      >
        {loading ? <CircularProgress size={24} sx={{ color: "white" }} /> : "Request Tokens"}
      </Button>
    </Box>
  );
};

export default FaucetForm;
