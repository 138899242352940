import React from "react";
import { CssBaseline, ThemeProvider, createTheme, Box, Typography } from "@mui/material";
import FaucetForm from "./components/FaucetForm";

const theme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#6a0dad", // Purple tone
    },
    background: {
      default: "#121212",
      paper: "#1e1e1e",
    },
    text: {
      primary: "#fff",
      secondary: "#b39ddb",
    },
  },
  typography: {
    fontFamily: "Arial, sans-serif",
  },
});

const App = () => (
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <Box sx={{ textAlign: "center", minHeight: "100vh", display: "flex", flexDirection: "column", paddingTop: "100px", justifyContent: "top", alignItems: "center" }}>
      <img src="/lendfinityLogo.svg" width={300} alt="logo" />
      
      <Typography variant="h4" color="primary" gutterBottom paddingTop="20px">
        Faucet
      </Typography>
      <Typography variant="subtitle1" color="text.secondary" gutterBottom>
        Enter your wallet address to receive tokens
      </Typography>
      <FaucetForm />
    </Box>
  </ThemeProvider>
);

export default App;
